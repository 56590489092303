import React from 'react';
import PageWrapper from 'Commons/PageWrapper';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'react-grid-system';
import { StaticImage } from 'gatsby-plugin-image';
import Defense from './Defense';
import './styles.scss';

const defenses = [
  {
    title: 'Camión Grupcom-R',
    description: 'Unidad blindada para despliegues tácticos',
    image: (
      <StaticImage
        src="../../images/industria/1_grupcom.png"
        loading="eager"
        placeholder="blurred"
        className="defense-image"
      />
    ),
    info: [
      'Nivel de resistencia balística: RB4',
      'Montado sobre plataforma Mercedes Benz 1726 4x4.',
      'Capacidad: 12 personas.',
      'Cuenta con sistema de cámaras de seguridad.',
    ],
  },
  {
    title: 'Camioneta A-Fenix',
    description: 'Unidad blindada para despliegues tácticos y reconocimiento',
    image: (
      <StaticImage
        src="../../images/industria/2_fenix.png"
        loading="eager"
        placeholder="blurred"
        className="defense-image"
      />
    ),
    info: [
      'Nivel de resistencia balística: RB4',
      'Montado sobre plataforma Ford F4000 4X4.',
      'Puede remplazarse por cualquier vehículo de entre 7 y 9 toneladas en el mercado.',
      'Capacidad: 4/6 personas.',
      'Cuenta con sistema de cámaras de seguridad.',
    ],
  },
  {
    title: "Valla de seguridad block 180'",
    description:
      'Valla antidisturbios montada sobre un remolque que cuenta con un medio de enganche a un vehículo tractor.Una vez en destino, la valla es desenganchada.',
    image: (
      <StaticImage
        src="../../images/industria/3_valla.png"
        loading="eager"
        placeholder="blurred"
        className="defense-image"
      />
    ),
    info: [
      'Se abre o cierra en 3 minutos.',
      'Puede transportarse con cualquier camioneta pick up.',
      'Solo se necesitan 2 personas para abrirla o cerrarla.',
      'Llega a desplegarse 18 metros.',
      'Ofrece otras opciones de longitud que se eligen según las necesidades de cada caso.',
      'Cuenta con una puerta para el paso de personal autorizado y tres troneras.',
    ],
  },
  {
    title: 'PUESTOS MÓVILES BLINDADOS',
    description:
      'Unidad blindada transportable para diferentes usos. Ejemplo: centro de monitoreo, comisaría, puesto de control, oficinas.',
    image: (
      <StaticImage
        src="../../images/industria/4_puestos.png"
        loading="eager"
        placeholder="blurred"
        className="defense-image"
      />
    ),
    info: [
      'Puede fabricarse en distintas medidas.',
      'El diseño del interior se puede armar modularmente.',
      'Se puede equipar a la necesidad del cliente.',
    ],
  },
  {
    title: 'TORRE VIGÍA MÓVIL BUHO HT',
    description:
      'Unidad móvil de vigilancia en altura montada sobre un remolque. Esta torre fue pensada y diseñada para controlar diferentes áreas de la ciudad. También puede ser requerida por empresas privadas de seguridad para la vigilancia en countries, playas de estacionamiento de hipermercados, shoppings, aeropuertos. etc.',
    image: (
      <StaticImage
        src="../../images/industria/5_torre.png"
        loading="eager"
        placeholder="blurred"
        className="defense-image"
      />
    ),
    info: [
      'Se eleva hasta 6 metros de altura.',
      'Visibilidad 360º. Sistema de monitoreo con 2 domos.',
      'Pantalla dentro de la cabina para control. Graba y transmite a cualquier centro de monitoreo.',
      'Se traslada fácilmente con cualquier pick up.',
      'Nivel de resistencia balística: RB2.',
    ],
  },
  {
    title: 'TORRE VIGÍA MÓVIL BUHO II',
    description:
      'Unidad móvil de vigilancia montada sobre un vehículo blindado. De fácil y rápido traslado, esta torre es ideal para controlar eventos o manifestaciones al aire libre con gran cantidad de personas en donde la vigilancia debe ser más ágil.',
    image: (
      <StaticImage
        src="../../images/industria/6_torre2.png"
        loading="eager"
        placeholder="blurred"
        className="defense-image"
      />
    ),
    info: [
      'Se eleva hasta 6 metros de altura.',
      'Visibilidad 360º. Sistema de monitoreo con cámaras con 2 domos. Pantalla dentro de la cabina para control.',
      'Graba y transmite a cualquier centro de monitoreo.',
      'Remolcada por unidad blindada, montada sobre plataforma Ford F4000 4x4. Puede reemplazarse por cualquier camioneta/camión de no menos de 5 toneladas.',
      'Nivel de resistencia balística: RB2 (torre) y RB3  (cabina del vehículo)',
    ],
  },
];

const IndustriaDeDefensa = () => {
  return (
    <PageWrapper dark>
      <Helmet>
        <title>Industria de Defensa | Interservit</title>
      </Helmet>
      <section className="industria-de-defensa content _center">
        <header>
          <Row>
            <Col xs={12} sm={8}>
              <h2 className="_sub-heading">Industria de defensa</h2>
              <p>
                Ofrecemos diferentes unidades para las fuerzas armadas de
                seguridad nacional y de países limítrofes, municipios o
                gobernaciones de las provincias. Pensadas y fabricadas para un
                uso de extrema exigencia, con diferentes características
                técnicas según el caso.
                <br />
                <br />
                Los niveles balísticos de estas unidades son RB2, RB3, RB4.
                Todas las unidades están registradas y patentadas.
              </p>
            </Col>
            <Col xs={12} sm={4} className="defenses--stamp">
              <StaticImage
                className="sello-certificado"
                src="../../images/tuv_certified.png"
              />
            </Col>
          </Row>
        </header>
        <section className="defenses">
          {defenses.map((defense) => (
            <Defense {...defense} />
          ))}
        </section>
      </section>
    </PageWrapper>
  );
};

export default IndustriaDeDefensa;
