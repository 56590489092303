import React, { useState } from 'react';
import './styles.scss';

const Defense = ({ image, title = "", description = "", info = [] }) => {
  const [showPopup, setShowPopup] = useState(false);

  const closePopUp = (e) => {
    e.stopPropagation();
    setShowPopup(false);
  }

  const openPopUp = () => setShowPopup(true);

  const stopPropagation =(e) => {
    e.stopPropagation();
  }

  return (
    <div className="defense">
      <div className="defense-image--wrapper" onClick={openPopUp}>
        {image}
      </div>
      <h3 className="defense-name">{title}</h3>
      <p className="defense-description">{description}</p>
      <span onClick={openPopUp} className="_link">
        VER MÁS
      </span>
      {showPopup ? (
        <div className="defense-popup" onClick={closePopUp}>
          <div className="defense-popup--content _center">
            <div className="defense-popup--top">
              <h3 className="_sub-heading">
                {title}
              </h3>
              <span onClick={closePopUp} />
            </div>
            <div className="defense-popup--bottom" onClick={stopPropagation}>
              {image}
              <div className="defense-info">
                <ul className="defense-info--list">
                  {info.map(item => (
                    <li>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
};

export default Defense
